import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function PublicPage() {
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1>Strona Publiczna</h1>
          <p className="lead">To jest strona dostępna dla wszystkich użytkowników, bez konieczności logowania.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default PublicPage;
