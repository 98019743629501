import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navigation from './components/Navigation';
import Home from './components/Home';
import PublicPage from './components/PublicPage';
import ProtectedPage from './components/ProtectedPage';
import LoginPage from './components/LoginPage';
import 'bootstrap/dist/css/bootstrap.min.css';

function AppContent() {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState('');
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('login_success') === 'true') {
      setIsAuthenticated(true);
      setUserEmail(params.get('email') || '');
      navigate('/', { replace: true }); // Usuń parametry z URL
    }
  }, [location, navigate]);

  const login = async (email) => {
    try {
      await axios.post('/api/send-auth-email', { email });
      alert('Sprawdź swoją skrzynkę e-mail, aby uzyskać link autoryzacyjny lub kod.');
    } catch (error) {
      if (error.response && error.response.status === 403) {
        alert('Nieautoryzowany adres e-mail.');
      } else {
        console.error('Błąd podczas wysyłania e-maila:', error);
        alert('Wystąpił błąd podczas wysyłania e-maila. Spróbuj ponownie.');
      }
    }
  };

  const verifyCode = async (code) => {
    try {
      const response = await axios.post('/api/verify-code', { code });
      if (response.data.success) {
        setIsAuthenticated(true);
        setUserEmail(response.data.email);
        navigate('/');
      }
    } catch (error) {
      console.error('Błąd podczas weryfikacji kodu:', error);
      alert('Nieprawidłowy kod weryfikacyjny. Spróbuj ponownie.');
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUserEmail('');
    navigate('/');
  };

  return (
    <div className="container">
      <Navigation isAuthenticated={isAuthenticated} userEmail={userEmail} logout={logout} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/public" element={<PublicPage />} />
        <Route
          path="/login"
          element={
            <LoginPage
              login={login}
              verifyCode={verifyCode}
              error={location.search.includes('error=invalid_code')}
            />
          }
        />
        <Route
          path="/protected"
          element={
            isAuthenticated ? <ProtectedPage userEmail={userEmail} /> : <Navigate to="/login" replace />
          }
        />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
