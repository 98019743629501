import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Navigation({ isAuthenticated, userEmail, logout }) {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">Moja Aplikacja</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/public">Strona Publiczna</Nav.Link>
            {isAuthenticated ? (
              <>
                <Nav.Link as={Link} to="/protected">Strona Chroniona</Nav.Link>
                <Navbar.Text className="ms-2">
                  Zalogowany jako: {userEmail}
                </Navbar.Text>
                <Button variant="outline-primary" onClick={logout} className="ms-2">Wyloguj</Button>
              </>
            ) : (
              <Nav.Link as={Link} to="/login">Logowanie</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
