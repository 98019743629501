import React from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';

function ProtectedPage() {
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1>Strona Chroniona</h1>
          <Alert variant="success">
            Gratulacje! Uzyskałeś dostęp do chronionej zawartości.
          </Alert>
        </Col>
      </Row>
    </Container>
  );
}

export default ProtectedPage;
