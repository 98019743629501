import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

function LoginPage({ login, verifyCode }) {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    login(email);
  };

  const handleCodeSubmit = (e) => {
    e.preventDefault();
    verifyCode(code);
  };

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col md={6}>
          <h2 className="mb-4">Logowanie</h2>
          <Form onSubmit={handleEmailSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Wprowadź email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Wyślij kod
            </Button>
          </Form>
          <hr className="my-4" />
          <Form onSubmit={handleCodeSubmit}>
            <Form.Group className="mb-3" controlId="formBasicCode">
              <Form.Label>Kod autoryzacyjny</Form.Label>
              <Form.Control
                type="text"
                placeholder="Wprowadź kod"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Weryfikuj kod
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginPage;
